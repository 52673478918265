import { Subject } from "rxjs";
import { Auftragsstatus } from "../model/aufteagsstatus";
import { AuftragEx, PersonalEx, TourStatusEx, ZeitEintragEx } from "../model/model";
import { ArbeitsartKonfiguration, Textdatei, TextdateiTyp, TourStatusTyp, ZeitEintrag, ZeitEintragZuordnungTyp } from "../model/swagger-model";
import { App } from "./app";
import { Logger } from "./app-error-logger";
import { AppConfig } from "./app.config";
import { AuftragHelper } from "./auftrag-helper";
import { TourHelper } from "./tour-helper";
import { UiHelper } from "./ui-helper";
import { Utils } from "./utils";
import * as moment from 'moment';
import { AuftragService } from "../auftrag.service";
import { I18N } from "./i18n";
import { StammdatenService } from "../stammdaten.service";
import { SystemService } from "../system.service";

const log = new Logger('Zeiterfassung');

export class Zeiterfassung {
    static changed = new Subject<void>();

    static getArbeitszeitArten(typ: 'Tour' | 'Auftrag'): ArbeitsartKonfiguration[] {
        let list: ArbeitsartKonfiguration[] = [];

        if (typ === 'Tour') {
            const stoerzeitenVerfuegbar = AppConfig.current.einstellungen['StoerzeitenVerfuegbar'] === 'ja';

            if (stoerzeitenVerfuegbar) {
                // Die Arbeitszeit-Arten sind leider noch als Störzeiten konfiguriert.
                // Das muss jetzt in das einheitliche Format "ArbeitszeitKonfiguration" gemappt werden
                let storezeiten = AppConfig.current.stoerzeitKonfiguration?.Stoerzeiten;

                if (storezeiten) {
                    list = storezeiten.filter(p => p.Typ == 'Tour').map(p => {
                        return {
                            Key: p.Key,
                            Name: p.Name,
                            Sort: p.Sort,
                            Referenz: '', // Referenz noch nicht verfügbar
                            Standard: false // Standard ist noch nicht verfügbar
                        } as ArbeitsartKonfiguration
                    });
                }
            }
        } else if (typ === 'Auftrag') {
            list = AppConfig.current.arbeitszeitKonfiguration?.AuftragArbeitszeitArten;

            if (!list) {
                list = [];
            }
        }

        list = list.sort((a, b) => {
            let result = a.Sort - b.Sort;

            if (result !== 0) {
                return result;
            }

            if (a.Standard && !b.Standard) {
                return -1;
            } else if (!a.Standard && b.Standard) {
                return 1;
            }

            if (a.Name < b.Name) {
                return -1;
            } else if (a.Name > b.Name) {
                return 1;
            }

            return 0;
        });

        // list.sort((a, b) => {
        //     if (a.Standard && !b.Standard) {
        //         return -1;
        //     } else if (!a.Standard && b.Standard) {
        //         return 0;
        //     }

        //     if (a.Name < b.Name) {
        //         return -1;
        //     } else if (a.Name > b.Name) {
        //         return 1;
        //     }

        //     return 0;
        // });

        return list;
    }

    static async loescheAuftragZeiterfassungenByArtKey(auftrag: AuftragEx, key: string): Promise<boolean> {
        log.info(`Lösche Auftrag-Zeiterfassung: ${auftrag.Key}, key=${key}`);

        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            UiHelper.showAlert(I18N.instant('ZeiterfassungOhneTourFehler'), 'Fehler');
            return false;
        }

        await Zeiterfassung.fixTourZeiterfassung(tourStatus);

        let auftragZeiten = Zeiterfassung.getAuftragszeiten(auftrag);

        if (auftrag.zeiterfassungLaeuft) {
            const laeuft = !!auftragZeiten.find(p => !p.EndDatum && p.ArtKey == key);

            if (laeuft) {
                auftrag.zeiterfassungLaeuft = false;
                auftrag.zeiterfassungUnterbrochen = true;
            }
        }

        let loeschListe = auftragZeiten.filter(p => p.ArtKey == key);
        let loeschGuids = loeschListe.map(p => p.Guid);

        tourStatus.Zeiterfassung.Eintraege = tourStatus.Zeiterfassung.Eintraege.filter(p => !loeschGuids.includes(p.Guid));

        auftrag.Zeiten = Zeiterfassung.getAuftragszeiten(auftrag);

        await TourHelper.speichereAktuelleTour();

        this.changed.next();

        return true;
    }

    static async starteTourZeiterfassung(zeitEintrag: ZeitEintrag): Promise<boolean> {
        log.info(`Starte Tour-Zeiterfassung: ${zeitEintrag.ArtName}`);

        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            UiHelper.showAlert(I18N.instant('ZeiterfassungOhneTourFehler'), 'Fehler');
            return false;
        }

        await Zeiterfassung.fixTourZeiterfassung(tourStatus);

        // Eventuell noch laufende Zeiterfassungen beenden
        await Zeiterfassung.beendeLaufendeZeiterfassungOhneRueckfrage();

        if (!zeitEintrag.Guid) {
            zeitEintrag.Guid = Utils.uuid();
        }

        if (!zeitEintrag.StartDatum) {
            zeitEintrag.StartDatum = Utils.nowIsoDateString();
        }

        zeitEintrag.PersonalKey = tourStatus.PersonalKey;
        zeitEintrag.BeifahrerKey = tourStatus.BeifahrerKey;
        zeitEintrag.BeifahrerKey2 = tourStatus.BeifahrerKey2;
        zeitEintrag.Fahrzeug = tourStatus.Fahrzeug;
        zeitEintrag.FahrzeugKey = tourStatus.FahrzeugKey;
        zeitEintrag.Anhaenger = tourStatus.Anhaenger;
        zeitEintrag.AnhaengerKey = tourStatus.AnhaengerKey;

        await Zeiterfassung.aktualisierePersonalNamen(zeitEintrag);

        tourStatus.Zeiterfassung.Eintraege.push(zeitEintrag);

        await TourHelper.speichereAktuelleTour();

        this.changed.next();

        return true;
    }

    static async starteAuftragZeiterfassung(auftrag: AuftragEx, zeitEintrag: ZeitEintrag): Promise<boolean> {
        log.info(`Starte Auftrag-Zeiterfassung: ${auftrag.Key}, ${zeitEintrag.ArtName}`);

        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            UiHelper.showAlert(I18N.instant('ZeiterfassungOhneTourFehler'), 'Fehler');
            return false;
        }

        await Zeiterfassung.fixTourZeiterfassung(tourStatus);

        // Eventuell noch laufende Zeiterfassungen beenden
        await Zeiterfassung.beendeLaufendeZeiterfassungOhneRueckfrage();

        // Der Zeiteintrag muss mit der Tour verknüpft werden.
        // Damit kann erkannt werden, welche Zeiteinträge in Aufträgen für eine andere Tour erfasst wurden.
        // Diese sind dann "archiviert" und können bei Tour-übergreifenden Aufträgen noch angezeigt werden
        zeitEintrag.TourGuid = tourStatus.Guid;

        if (!zeitEintrag.Guid) {
            zeitEintrag.Guid = Utils.uuid();
        }

        if (!zeitEintrag.StartDatum) {
            zeitEintrag.StartDatum = Utils.nowIsoDateString();
        }

        zeitEintrag.PersonalKey = tourStatus.PersonalKey;
        zeitEintrag.BeifahrerKey = tourStatus.BeifahrerKey;
        zeitEintrag.BeifahrerKey2 = tourStatus.BeifahrerKey2;
        zeitEintrag.Fahrzeug = tourStatus.Fahrzeug;
        zeitEintrag.FahrzeugKey = tourStatus.FahrzeugKey;
        zeitEintrag.Anhaenger = tourStatus.Anhaenger;
        zeitEintrag.AnhaengerKey = tourStatus.AnhaengerKey;

        await Zeiterfassung.aktualisierePersonalNamen(zeitEintrag);

        tourStatus.Zeiterfassung.Eintraege.push(zeitEintrag);

        auftrag.zeiterfassungLaeuft = true;
        auftrag.zeiterfassungUnterbrochen = false;
        auftrag.Zeiten = Zeiterfassung.getAuftragszeiten(auftrag);

        await TourHelper.speichereAktuelleTour();

        this.changed.next();

        return true;
    }

    static async aktualisierePersonalNamen(eintrag: ZeitEintrag) {
        const fahrerListe = await StammdatenService.instance.getFahrerListe(false);

        if (!fahrerListe || !fahrerListe.length) {
            return;
        }

        if (eintrag.PersonalKey) {
            const fahrer = fahrerListe.find(p => p.Key == eintrag.PersonalKey);

            if (fahrer) {
                eintrag.PersonalName = fahrer.anzeigeName;
            }
        } else {
            eintrag.PersonalName = null;
        }

        if (eintrag.BeifahrerKey) {
            const fahrer = fahrerListe.find(p => p.Key == eintrag.BeifahrerKey);

            if (fahrer) {
                eintrag.BeifahrerName = fahrer.anzeigeName;
            }
        } else {
            eintrag.BeifahrerName = null;
        }

        if (eintrag.BeifahrerKey2) {
            const fahrer = fahrerListe.find(p => p.Key == eintrag.BeifahrerKey2);

            if (fahrer) {
                eintrag.BeifahrerName2 = fahrer.anzeigeName;
            }
        } else {
            eintrag.BeifahrerName2 = null;
        }
    }

    /**
     * Berechnet die Gesamt-Dauert der aktiven Zeit in Sekunden.
     * Es wird zudem die 'Dauer'Property der Zeiteinträge bei Bedarf aktualisiert
     */
    static BerechneAktiveDauerInSekundenFuerAuftragszeiten(zeiten: ZeitEintrag[]) {
        let summeDauer = 0;

        if (zeiten) {
            for (const zeit of zeiten) {
                summeDauer += Zeiterfassung.getDauerInSekunden(zeit);
            }
        }

        return summeDauer;
    }

    static getDauerInSekunden(zeit: ZeitEintragEx) {
        let dauer = 0;

        if (zeit.StartDatum && zeit.EndDatum) {
            // Zeitaufzeichnung ist abgeschlossen
            if (zeit.Dauer == null || true) {
                const startMoment = moment(zeit.StartDatum);
                const endMoment = moment(zeit.EndDatum);
                zeit.Dauer = endMoment.diff(startMoment, 'seconds');
            }

            dauer = zeit.Dauer;
        } else if (zeit.StartDatum && !zeit.EndDatum) {
            // Zeiterfassung läuft noch

            const startMoment = moment(zeit.StartDatum);
            const now = moment();
            dauer = now.diff(startMoment, 'seconds');
        }

        return dauer;
    }

    static async beendeLaufendeZeiterfassungMitRueckfrage(): Promise<boolean> {
        return Zeiterfassung.beendeLaufendeZeiterfassung(true);
    }

    static async beendeLaufendeZeiterfassungOhneRueckfrage(): Promise<boolean> {
        return Zeiterfassung.beendeLaufendeZeiterfassung(false);
    }

    private static async beendeLaufendeZeiterfassung(mitRueckfrage: boolean): Promise<boolean> {
        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            // Keine aktive Tour. Also alles ok (sollte aber nie eintreten)
            return true;
        }

        let beendet = false;

        Zeiterfassung.fixTourZeiterfassung(tourStatus);

        const zeiten = tourStatus.Zeiterfassung.Eintraege;

        let laufendeEintraege = zeiten.filter(p => !p.EndDatum);

        if (laufendeEintraege.length > 0) {
            if (mitRueckfrage) {
                let eintrag = laufendeEintraege[0];

                let auftragInfo: string;

                if (eintrag.Auftragsnummer) {
                    auftragInfo = 'Auftrag ' + eintrag.Auftragsnummer + ' (' + eintrag.ArtName + ')';
                } else {
                    auftragInfo = eintrag.ArtName;
                }

                const ok = await UiHelper.confirmJaNein(`Zeiterfassung für ${auftragInfo} läuft noch. Laufende Zeiterfassung beenden?`);

                if (!ok) {
                    return false;
                }
            }

            for (const zeit of laufendeEintraege) {
                zeit.EndDatum = Utils.nowIsoDateString();

                // Schreibe die angemeldeten Fahrer in den Eintrag.
                zeit.PersonalKey = tourStatus.PersonalKey;
                zeit.BeifahrerKey = tourStatus.BeifahrerKey;
                zeit.BeifahrerKey2 = tourStatus.BeifahrerKey2;

                await Zeiterfassung.aktualisierePersonalNamen(zeit);

                beendet = true;

                log.info(`Beende laufende Zeiterfassung: ${zeit.AuftragKey}, ${zeit.ArtName}`);

                if (zeit.AuftragKey) {
                    // Die Zeiterfassung muss auch im Auftrag aktualisiert werden.
                    // Ansonsten läuft dort die Zeit weiter
                    const auftrag = await AuftragService.instance.getAuftrag(zeit.AuftragKey, false);

                    if (auftrag) {
                        auftrag.Zeiten = Zeiterfassung.getAuftragszeiten(auftrag);
                        auftrag.zeiterfassungLaeuft = false;

                        AuftragService.instance.speichereAuftrag(auftrag);
                    }
                }
            }

            Zeiterfassung.BerechneAktiveDauerInSekundenFuerAuftragszeiten(zeiten);

            if (beendet) {
                // Zeiten sofort an ReCoLog schicken
                Zeiterfassung.sendeZeiten(laufendeEintraege);

                await TourHelper.speichereAktuelleTour();
            }
        }

        this.changed.next();

        return true;
    }

    /**
     * Sendet sie Zeit-Einträge direkt an ReCoLog.
     * Wird für Hubert Schmid benötigt.
     */
    static sendeZeiten(zeiten: ZeitEintrag[]) {
        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!zeiten?.length) {
            return;
        }

        const textdatei: Textdatei = {
            typ: TextdateiTyp.TourStatus,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            tourStatus: {
                Statuszeit: Utils.nowIsoDateString(),
                Status: TourStatusTyp.Zeiterfassung,
                TourKey: tourStatus.TourKey,
                TourKeys: tourStatus.TourKeys,
                TourTyp: tourStatus.TourTyp,
                Geraet: tourStatus.Geraet,
                GeraeteGruppe: tourStatus.GeraeteGruppe,
                Personalnummer: tourStatus.Personalnummer,
                PersonalKey: tourStatus.PersonalKey,
                BeifahrerKey: tourStatus.BeifahrerKey,
                BeifahrerKey2: tourStatus.BeifahrerKey2,
                Fahrzeug: tourStatus.Fahrzeug,
                FahrzeugKey: tourStatus.FahrzeugKey,
                Datum: tourStatus.Datum,
                Zeiterfassung: {
                    Eintraege: zeiten
                },
            }
        };

        // Kein await hier aus Performance-Gründen
        SystemService.instance.sendeTextdatei(textdatei, false);
    }

    static async unterbrecheAuftragZeiterfassung(auftrag: AuftragEx): Promise<void> {
        log.info(`Untebreche Auftrag-Zeiterfassung: ${auftrag.Key}`);

        auftrag.zeiterfassungLaeuft = false;
        auftrag.zeiterfassungUnterbrochen = true;

        const zeiten = Zeiterfassung.getAuftragszeiten(auftrag);

        const laufendeEintraege = zeiten.filter(p => !p.EndDatum);

        for (const zeit of laufendeEintraege) {
            zeit.EndDatum = Utils.nowIsoDateString();
        }

        auftrag.Zeiten = [...zeiten];

        AuftragHelper.BerechneAktiveDauerInSekunden(auftrag);

        // Zeiten sofort an ReCoLog schicken
        Zeiterfassung.sendeZeiten(laufendeEintraege);

        await TourHelper.speichereAktuelleTour();

        this.changed.next();
    }

    static getAuftragszeiten(auftrag: AuftragEx): ZeitEintrag[] {
        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            // Keine aktive Tour.
            // Auftragszeiten werden in der Tour gespeichert.
            if (auftrag.Zeiten) {
                for (const zeit of auftrag.Zeiten) {
                    zeit.IsArchiv = true;
                }

                return auftrag.Zeiten;
            }

            return [];
        }

        if (auftrag.TourKey != tourStatus.Key && auftrag.Auftragsstatus >= Auftragsstatus.Abgeschlossen) {
            // Auftrag wurde zu einer anderen Tour gemeldet.
            if (auftrag.Zeiten) {
                return auftrag.Zeiten;
            }

            return [];
        }

        Zeiterfassung.fixTourZeiterfassung(tourStatus);

        const zeiten = tourStatus.Zeiterfassung.Eintraege.filter(p => p.Zuordnung === ZeitEintragZuordnungTyp.Auftrag && p.AuftragKey === auftrag.Key);

        // Zusätzlich noch alle Zeiten, die zu einer anderen Tour erfasst wurden.
        if (auftrag.Zeiten) {
            let archivZeiten = auftrag.Zeiten.filter(p => p.TourGuid != tourStatus.Guid);

            for (const zeit of archivZeiten) {
                zeit.IsArchiv = true;
                zeiten.push(zeit);
            }
        }

        return zeiten;
    }

    static async fixTourZeiterfassung(tourStatus: TourStatusEx): Promise<void> {
        let korrigiert = false;

        if (!tourStatus.Zeiterfassung) {
            tourStatus.Zeiterfassung = { Eintraege: [] };
            korrigiert = true;
        }

        if (!tourStatus.Zeiterfassung.Eintraege) {
            tourStatus.Zeiterfassung.Eintraege = [];
            korrigiert = true;
        }

        for (const m of tourStatus.Zeiterfassung.Eintraege) {
            if (!m.Guid) {
                m.Guid = Utils.uuid();
                korrigiert = true;
            }

            if (!m.TourGuid) {
                m.TourGuid = tourStatus.Guid;
            }
        }

        if (korrigiert) {
            await TourHelper.speichereAktuelleTour();

            this.changed.next();
        }
    }

    static getLaufendeZeitText(eintrag: ZeitEintrag): string {
        if (!eintrag || !eintrag.StartDatum) {
            return '';
        }

        const startDatum = moment(eintrag.StartDatum);
        const now = moment();

        const duration = moment.duration(now.diff(startDatum));
        const aktivDauer = ("0" + Math.floor(duration.asHours())).slice(-2) + ':' + ("0" + duration.minutes()).slice(-2) + ':' + ("0" + duration.seconds()).slice(-2).toString();

        return aktivDauer;
    }

    static getDauerText(eintrag: ZeitEintrag) {
        const dauerSekunden = Zeiterfassung.getDauerInSekunden(eintrag);
        const dauerMinuten = Math.round(dauerSekunden / 60);

        let dauer = '';

        if (eintrag.EndDatum) {
            dauer = dauerMinuten + ' ' + I18N.instant('Minuten');

            if (dauerMinuten < 1) {
                dauer = Math.round(dauerSekunden) + ' ' + I18N.instant('Sekunden');
            } else if (dauerMinuten == 1) {
                dauer = dauerMinuten + ' ' + I18N.instant('Minute');
            }
        } else {
            // Es soll die Dauer gar nicht angezeigt werden. Zumindest nicht in der Tour-Zeiterfassung-Liste.
        }

        return dauer;
    }

    static async loescheZeiterfassungEintrag(eintrag: ZeitEintrag) {
        const tourStatus = App.current.aktuelleTour.getValue() as TourStatusEx;

        if (!tourStatus) {
            UiHelper.showErrorLight(I18N.instant('TourNichtGestartetLoeschen'));
            return;
        }

        Zeiterfassung.fixTourZeiterfassung(tourStatus);

        tourStatus.Zeiterfassung.Eintraege = tourStatus.Zeiterfassung.Eintraege.filter(p => p.Guid != eintrag.Guid);

        await TourHelper.speichereAktuelleTour();

        this.changed.next();
    }

    static getPersonalText(eintrag: ZeitEintragEx): string {
        return Utils.joinNotEmpties(', ', eintrag.PersonalName, eintrag.BeifahrerName, eintrag.BeifahrerName2);
    }

    /**
     * Aktualisiert die BeifahrerListe.
     * Muss beim Tour-Start und beim Ummelden von Beifahrern aufgerufen werden.
     */
    static async korrigiereBeifahrerListe(tourStatus: TourStatusEx) {
        if (!tourStatus) {
            return;
        }

        if (!tourStatus.BeifahrerListe) {
            tourStatus.BeifahrerListe = [];
        }

        const fahrerListe = await StammdatenService.instance.getFahrerListe(false);

        if (!fahrerListe || !fahrerListe.length) {
            return;
        }

        let beifahrerKeys = [
            tourStatus.BeifahrerKey,
            tourStatus.BeifahrerKey2
        ];

        beifahrerKeys = beifahrerKeys.filter(p => !!p);

        for (const personalKey of beifahrerKeys) {
            let beifahrerDaten = tourStatus.BeifahrerListe.find(p => p.PersonalKey == personalKey && !p.AngemeldetBis);

            if (!beifahrerDaten) {
                const fahrer = fahrerListe.find(p => p.Key == personalKey);

                beifahrerDaten = {
                    Id: Utils.uuid(),
                    PersonalKey: personalKey,
                    AngemeldetVon: Utils.nowIsoDateString(),
                }

                if (fahrer) {
                    beifahrerDaten.Vorname = fahrer.vorname;
                    beifahrerDaten.Nachname = fahrer.nachname;
                    beifahrerDaten.Personalnummer = fahrer.personalnummer;
                } else {
                    beifahrerDaten.Vorname = personalKey;
                    beifahrerDaten.Nachname = '';
                    beifahrerDaten.Personalnummer = personalKey;
                }

                tourStatus.BeifahrerListe.push(beifahrerDaten);
            }
        }

        for (const beifahrerDaten of tourStatus.BeifahrerListe) {
            if (!beifahrerDaten.Id) {
                beifahrerDaten.Id = Utils.uuid();
            }

            if (!beifahrerKeys.includes(beifahrerDaten.PersonalKey)) {
                // Beifahrer "abmelden"
                beifahrerDaten.AngemeldetBis = Utils.nowIsoDateString();
            }
        }
    }
}