import { Injectable, Optional } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteService } from './remote.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

import { Logger } from './helper/app-error-logger';
import { SystemService } from './system.service';
import { NotificationEventResponse } from '@ionic-native/push/ngx';
import { StammdatenService } from './stammdaten.service';
import { App } from './helper/app';
import { Personal, Textdatei, TextdateiTyp, Tour, TourStatus, TourTyp, Fahrzeug, TourStatusTyp } from './model/swagger-model';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from './helper/app.config';

import { AppStorage } from './helper/app-storage';
import { Utils } from './helper/utils';
import { UiHelper } from './helper/ui-helper';
import { AuftragService } from './auftrag.service';

import * as moment from 'moment';
import { Constants } from './model/constants';
import { ModalController } from '@ionic/angular';
import { FuehrerscheinkontrolleModalPage } from '../shared/modal/fuehrerscheinkontrolle-modal/fuehrerscheinkontrolle-modal.page';
import { TourHelper } from './helper/tour-helper';
import { Zeiterfassung } from './helper/zeiterfassung-helper';

declare var cordova: any;

const ONE_DAY = 24 * 60 * 60 * 1000; // ms
const ZWANZIG_STUNDEN = 20 * 60 * 60 * 1000; // ms

const DUMMY_FAHRER: Personal = {
    personalnummer: 'DUMMY',
    nachname: '',
    vorname: ''
};

const log = new Logger('TourService');

@Injectable({
    providedIn: 'root'
})
export class TourService {
    static instance: TourService;

    /**
     * Liste mit Push-Notifications die noch verarbeitet werden müssen.
     */
    notitifcationZurVerarbeitung: NotificationEventResponse[] = [];

    private isReady = new BehaviorSubject<boolean>(false);

    private arbeitszeitWarnungIntervalId: any = null;

    /**
     * Enthält je Arbeitszeit-Warnung einen Eintrag wann die letzte Warnung dazu angezeigt wurde
     */
    private letzteArbeitszeitWarnungen: { [key: string]: number; } = {};

    /**
     * Liste mit Warnungen die gerade angezeigt werden.
     * Wird verwendet, damit Warnungen nicht doppelt angezeigt werden
     */
    private aktiveArbeitszeitWarnungen: { [key: string]: boolean; } = {};

    private pruefeArbeitszeitwarnungLaeuft = false;

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private auftragService: AuftragService,
        private stammdatenService: StammdatenService,
        private modalController: ModalController,
        private backgroundMode: BackgroundMode,
        private systemService: SystemService) {

        TourService.instance = this;

        App.current.anmeldungErforderlich.subscribe(erforderlich => {
            if (erforderlich !== null) {
                if (!erforderlich) {
                    log.info('Anmeldung nicht erforderlich. Melde DUMMY-Fahrer an');
                    this.anmeldenFahrer(DUMMY_FAHRER);
                } else {
                    log.info('Anmeldung erforderlich.');

                    if (App.current.fahrer.getValue() === DUMMY_FAHRER) {
                        log.info('DUMMY-Fahrer ist angemeldet. Abmelden.');
                        this.abmeldenFahrer();
                    }
                }
            }
        });

        App.current.configRefreshed.subscribe(() => this.onConfigRefreshed());

        App.current.aktuelleTour.subscribe(() => this.aktualisiereArbeitszeitWarnungTimer());

        App.current.aktuelleTour.subscribe(tour => {
            if (!tour) {
                // Tour wurde beendet. Damit auch alle Arbeitszeit-Warnungen zurücksetzen
                this.letzteArbeitszeitWarnungen = {};
                this.aktiveArbeitszeitWarnungen = {};
            }
        })

        setInterval(() => {
            this.pruefeAutomatischAbmelden();
        }, 15 * 60 * 1000);
    }

    public async init() {
        log.debug('init');

        try {
            let fahrer = await AppStorage.current.get('angemeldeter-fahrer', true, true);
            const fahrzeug = await AppStorage.current.get(Constants.ANGEMELDETES_FAHRZEUG, true, true);
            const anhaenger = await AppStorage.current.get(Constants.ANGEMELDETER_ANHAENGER, true, true);

            if (fahrer) {
                // Neu von den Stammdaten laden
                fahrer = await this.stammdatenService.GetFahrerByPersonalnummer(fahrer.personalnummer, false);

                if (fahrer) {
                    App.current.fahrer.next(fahrer);
                    App.current.fahrerAngemeldet.next(true);
                }
            }

            if (fahrzeug) {
                App.current.fahrzeug.next(fahrzeug);
            }

            if (anhaenger) {
                App.current.anhaenger.next(anhaenger);
            }

            const aktuelleTour: TourStatus = await AppStorage.current.get('aktuelleTour', true, true);

            if (aktuelleTour) {
                if (!aktuelleTour.Guid) {
                    aktuelleTour.Guid = Utils.uuid();
                }

                App.current.tourGestartet.next(true);
                App.current.aktuelleTour.next(aktuelleTour);
            } else {
                const aktuellGeladeneTour: TourStatus = await AppStorage.current.get('aktuellGeladeneTour');

                if (aktuellGeladeneTour) {
                    App.current.aktuellGeladeneTour.next(aktuellGeladeneTour);
                }
            }

            log.info('init', { fahrer, fahrzeug });
        } catch (err) {
            log.error('init', err);
        }

        this.systemService.tourenAktualisieren.subscribe(async () => {
            log.debug('tourenAktualisieren');
            await this.syncTourenMitServer('tourenAktualisieren');
        });

        await this.pruefeAutomatischAbmelden();

        log.debug('init completed');

        this.isReady.next(true);
    }

    onConfigRefreshed(): void {
        log.debug('onConfigRefreshed');
        this.aktualisiereArbeitszeitWarnungTimer();
    }

    aktualisiereArbeitszeitWarnungTimer() {
        log.debug('aktualisiereArbeitszeitWarnungTimer')

        if (this.arbeitszeitWarnungIntervalId) {
            log.debug('Starte arbeitszeitWarnungIntervalId')
            clearInterval(this.arbeitszeitWarnungIntervalId);
            this.arbeitszeitWarnungIntervalId = null;
        }

        if (!App.current.aktuelleTour.getValue()) {
            return;
        }

        if (AppConfig.current.arbeitszeitKonfiguration?.Warnungen?.length) {
            log.debug('Arbeitszeit-Warnungen konfiguriert und Tour aktiv. Starte Intervall')

            this.arbeitszeitWarnungIntervalId = setInterval(() => this.pruefeArbeitszeitwarnungAnzeigen(), 10 * 1000);
        }
    }

    public async ready(): Promise<void> {
        if (this.isReady.getValue()) {
            return;
        }

        return new Promise(resolve => {
            const subscription = this.isReady.subscribe((value) => {
                if (value) {
                    subscription.unsubscribe();
                    resolve();
                }
            });
        });
    }

    private async pruefeAutomatischAbmelden() {
        log.debug('pruefeAutomatischAbmelden');

        try {
            const aktuelleTour = App.current.aktuelleTour.getValue();

            if (aktuelleTour && aktuelleTour.Statuszeit) {
                if (AppConfig.current.einstellungen.NachtsAutomatischTourEnde === 'ja') {
                    const startDatum = moment(aktuelleTour.Statuszeit).format('DD.MM.YYYY');
                    const heute = moment().format('DD.MM.YYYY');

                    if (startDatum != heute) {
                        log.info('Tour automatisch beenden, da Tour-Start an vorherigem Tag', { startDatum, heute });

                        this.beendeTour({
                            Status: TourStatusTyp.Ende,
                            TourKey: aktuelleTour.TourKey,
                            TourKeys: aktuelleTour.TourKeys,
                            TourTyp: aktuelleTour.TourTyp,
                            TourDatum: aktuelleTour.TourDatum,
                            TourBezeichnung: aktuelleTour.TourBezeichnung,
                            Fahrzeug: aktuelleTour.Fahrzeug,
                            FahrzeugKey: aktuelleTour.FahrzeugKey,
                            Anhaenger: aktuelleTour.Anhaenger,
                            AnhaengerKey: aktuelleTour.AnhaengerKey,
                            Kilometerstand: 9999999,
                            Datum: heute,
                            Uhrzeit: moment().format('00:00'),
                            AbgeschlosseneAuftraege: aktuelleTour.AbgeschlosseneAuftraege,
                            Personalnummer: aktuelleTour.Personalnummer,
                            PersonalKey: aktuelleTour.PersonalKey,
                            UnterMandant: aktuelleTour.UnterMandant
                        })
                    }
                }
            }
        } catch (err) {
            log.error('pruefeAutomatischAbmelden Tour', err);
        }

        try {
            const fahrer = App.current.fahrer.getValue();

            if (fahrer) {
                if (AppConfig.current.einstellungen.NachtsAutomatischAbmelden === 'ja') {
                    const datumIsoStr = await AppStorage.current.get('angemeldeter-fahrer-date', true, true);

                    if (datumIsoStr) {
                        const anmeldeDatum = moment(datumIsoStr).format('DD.MM.YYYY');
                        const heute = moment().format('DD.MM.YYYY');

                        if (anmeldeDatum != heute) {
                            log.info('Automatisch abmelden, da Anmeldung an vorherigem Tag', { anmeldeDatum, heute });

                            this.abmeldenFahrer();
                        }
                    }
                }
            }
        } catch (err) {
            log.error('pruefeAutomatischAbmelden Fahrer', err);
        }
    }

    public async getAngemeldetenFahrer(): Promise<Personal> {
        const fahrer = await AppStorage.current.get('angemeldeter-fahrer', true, true);

        // const datumIsoStr = await AppStorage.current.get('angemeldeter-fahrer-date');

        // if (fahrer != null) {
        //     if (AppConfig.current.einstellungen.NachtsAutomatischAbmelden === 'ja') {
        //         if (datumIsoStr && moment(datumIsoStr).diff(moment(), 'hours') < 20) {
        //             // Fahrer ist noch angemeldet
        //             return fahrer;
        //         } else {
        //             await this.abmeldenFahrer();
        //         }
        //     } else {
        //         return fahrer;
        //     }
        // }

        return fahrer;
    }

    public async abmeldenFahrer(): Promise<void> {
        const fahrer = App.current.fahrer.getValue();

        log.info('abmeldenFahrer', fahrer);

        await AppStorage.current.set('angemeldeter-fahrer', null, true, true);
        await AppStorage.current.set('angemeldeter-fahrer-date', '', true, true);

        App.current.fahrer.next(null);
        App.current.fahrerAngemeldet.next(false);

        if (fahrer && fahrer.personalnummer != 'DUMMY') {
            // Personal war angemeldet. Abmelde-Information an MES schicken
            const textdatei: Textdatei = {
                typ: TextdateiTyp.Anmeldung,
                datum: moment().toISOString(),
                geraeteNummer: AppConfig.current.geraeteNummer,
                anmeldung: {
                    Datum: moment().toISOString(),
                    Modus: '',
                    PersonalKey: fahrer.Key,
                    Personalnummer: fahrer.personalnummer,
                    Typ: 'abmelden',
                    UnterMandant: AppConfig.current.UnterMandant
                },
            };

            const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiAnmeldung === 'ja';

            // Kein await hier aus Performance-Gründen
            this.systemService.sendeTextdatei(textdatei, mitGpsPosition);
        }
    }

    public async anmeldenFahrer(fahrer: Personal, modus?: 'nfc' | 'personalnummer'): Promise<boolean> {
        log.info('anmeldenFahrer', fahrer);

        if (!fahrer) {
            throw new Error(`Parameter 'fahrer' darf nicht NULL sein`);
        }

        if (modus) {
            // Führerscheinkontrolle ausführen, falls erforderlich
            const ok = await this.pruefeFuehrescheinkontrolle(fahrer);

            if (!ok) {
                return false;
            }
        }

        await AppStorage.current.set('angemeldeter-fahrer', fahrer, true, true);
        await AppStorage.current.set('angemeldeter-fahrer-date', moment().toISOString(true), true, true);

        App.current.fahrer.next(fahrer);
        App.current.fahrerAngemeldet.next(true);

        if (modus) {
            const textdatei: Textdatei = {
                typ: TextdateiTyp.Anmeldung,
                datum: moment().toISOString(),
                geraeteNummer: AppConfig.current.geraeteNummer,
                anmeldung: {
                    Datum: moment().toISOString(),
                    Modus: modus,
                    Personalnummer: fahrer.personalnummer,
                    PersonalKey: fahrer.Key,
                    Typ: 'anmelden',
                    UnterMandant: AppConfig.current.UnterMandant
                },
            };

            const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiAnmeldung === 'ja';

            // Kein await hier aus Performance-Gründen
            this.systemService.sendeTextdatei(textdatei, mitGpsPosition);
        }

        return true;
    }

    async pruefeFuehrescheinkontrolle(personal: Personal): Promise<boolean> {
        if (!personal) {
            return true;
        }

        if (AppConfig.current.einstellungen.FuehrerscheinkontrolleVerfuegbar !== 'ja') {
            return true;
        }

        if (!personal.Eigenschaften) {
            personal.Eigenschaften = [];
        }

        let istAbgelaufen: 'ja' | 'nein' | 'unbekannt' = 'unbekannt';
        let istIntervallFaellig: 'ja' | 'nein' | 'unbekannt' = 'unbekannt';
        let restTage = Utils.parseInt(AppConfig.current.einstellungen.FuehrerscheinkontrolleMaxVerschiebenTage);

        try {
            // Prüfe ob der Führerschein eventuell schon abgelaufen ist.
            // Dann wird auf jeden Fall eine Prüfung notwendig

            const now = moment();

            const ablaufdatumKey = AppConfig.current.einstellungen.FuehrerscheinkontrolleAblaufdatumKey;
            const letztePruefungKey = AppConfig.current.einstellungen.FuehrerscheinkontrolleLetztePruefungKey;

            const ablaufdatumEigenschaft = personal.Eigenschaften.find(p => p.Key === ablaufdatumKey);

            if (ablaufdatumEigenschaft?.Ablaufdatum) {
                const ablaufdatum = moment(ablaufdatumEigenschaft.Ablaufdatum);

                if (ablaufdatum.isSameOrBefore(now)) {
                    istAbgelaufen = 'ja';
                } else {
                    istAbgelaufen = 'nein';
                }
            }

            const letztePruefungEigenschaft = personal.Eigenschaften.find(p => p.Key === letztePruefungKey);

            if (letztePruefungEigenschaft?.Ablaufdatum) {
                const intervall = AppConfig.current.einstellungen.FuehrerscheinkontrolleIntervall;
                const maxVerschiebenTage = Utils.parseInt(AppConfig.current.einstellungen.FuehrerscheinkontrolleMaxVerschiebenTage);

                let neachstePruefung: moment.Moment;
                const letztePruefung = moment(letztePruefungEigenschaft.Ablaufdatum);

                switch (intervall) {
                    case 'jahr':
                        if (letztePruefung.isBefore(now, 'year')) {
                            neachstePruefung = moment('01.01.' + now.year(), 'DD.MM.YYYY HH:mm');
                        } else {
                            neachstePruefung = moment('01.01.' + (now.year() + 1), 'DD.MM.YYYY HH:mm');
                        }
                        break;

                    case 'quartal':
                        {
                            let m = moment('01.01.' + now.year());

                            if (letztePruefung.isBefore(now, 'quarter')) {
                                // Prüfung ist dieses Quartal fällig
                                neachstePruefung = m.quarter(now.quarter());
                            } else {
                                // Prüfung ist erst nächstes Quartal fällig
                                neachstePruefung = now.clone().startOf('quarter').add(3, 'month');
                            }

                            break;
                        }

                    case 'monat':
                        {
                            if (letztePruefung.isBefore(now, 'month')) {
                                // Prüfung ist diesen Monat wieder fällig
                                neachstePruefung = now.clone().startOf('month');
                            } else {
                                // Prüfung ist nächsten Monat wieder fällig
                                neachstePruefung = now.clone().add(1, 'month').startOf('month');
                            }
                            break;
                        }

                    case 'woche':
                        if (letztePruefung.isBefore(now, 'week')) {
                            // Prüfung ist diese Woche fällig
                            neachstePruefung = now.clone().startOf('week');
                        } else {
                            // Prüfung ist nächste Woche wieder fällig
                            neachstePruefung = now.clone().add(1, 'week').startOf('week');
                        }
                        break;

                    case 'tag':
                        if (letztePruefung.isBefore(now, 'day')) {
                            // Prüfung ist heute fällig
                            neachstePruefung = now.clone().startOf('day');
                        } else {
                            // Prüfung ist morgen wieder fällig
                            neachstePruefung = now.clone().add(1, 'day').startOf('day');
                        }
                        break;

                    default:
                        neachstePruefung = now.clone();
                        UiHelper.showError('Führerscheinkontrolle Intervall ungültig: ' + intervall);
                        break;
                }

                if (neachstePruefung.isBefore(now, 'day')) {
                    istIntervallFaellig = 'ja';

                    // Berechne Tage wie lange die Führerscheinkontrolle noch verschoben werden kann
                    restTage = maxVerschiebenTage - now.clone().diff(neachstePruefung, 'days');
                } else {
                    istIntervallFaellig = 'nein';
                }
            }
        } catch (err) {
            log.error(`pruefeFuehrescheinkontrolle ${personal.Key}: ${Utils.getErrorMessage(err)}`, err);
        }

        if (AppConfig.current.einstellungen.FuehrerscheinkontrolleAblaufdatumErfassen !== 'ja') {
            // Wenn das Datum nicht erfasst wurde, dann kann es auch nicht abgelaufen sein
            istAbgelaufen = 'nein';
        }

        let pruefungErforderlich =
            istAbgelaufen === 'ja'
            || istIntervallFaellig === 'ja'
            || (istIntervallFaellig === 'unbekannt')
            || (istAbgelaufen === 'unbekannt');

        if (pruefungErforderlich) {
            if (istAbgelaufen === 'ja') {
                restTage = 0;
            }

            const modal = await this.modalController.create({
                component: FuehrerscheinkontrolleModalPage,
                componentProps: {
                    'personal': personal,
                    'restTage': restTage
                }
            });

            await modal.present();

            const result = await modal.onDidDismiss();

            log.debug('FuehrerscheinkontrolleModalPage onDidDismiss: ' + result?.data);

            const data = result?.data;

            switch (data) {
                case 'OK':
                    // Führerscheinkontrolle erfolgreich durchgeführt
                    return true;

                case 'Verschoben':
                    // Führerscheinkontrolle wurde verschoben (erlaubt)
                    return true;

                default:
                    // Abbruch. Fahrer muss wieder abgemeldet werden
                    return false;
            }
        } else {
            // Keien Prüfung erforderlich
            return true;
        }
    }

    public async anmeldenFahrzeug(fahrzeug: Fahrzeug, anhaenger: Fahrzeug): Promise<void> {
        log.info('anmeldenFahrzeug', fahrzeug);

        await AppStorage.current.set(Constants.ANGEMELDETES_FAHRZEUG, fahrzeug, true, true);
        await AppStorage.current.set(Constants.ANGEMELDETES_FAHRZEUG_DATE, Date.now(), true, true);
        await AppStorage.current.set(Constants.LETZTES_ANGEMELDETES_FAHRZEUG, fahrzeug.kennzeichen, true, true);

        if (anhaenger) {
            await AppStorage.current.set(Constants.ANGEMELDETER_ANHAENGER, anhaenger, true, true);
            await AppStorage.current.set(Constants.ANGEMELDETER_ANHAENGER_DATE, Date.now(), true, true);
            await AppStorage.current.set(Constants.LETZTER_ANGEMELDETER_ANHAENGER, anhaenger.kennzeichen, true, true);
        } else {
            await AppStorage.current.remove(Constants.ANGEMELDETER_ANHAENGER);
            await AppStorage.current.remove(Constants.ANGEMELDETER_ANHAENGER_DATE);
            await AppStorage.current.remove(Constants.LETZTER_ANGEMELDETER_ANHAENGER);
        }

        App.current.fahrzeug.next(fahrzeug);
        App.current.anhaenger.next(anhaenger);
    }

    public async abmeldenFahrzeug(): Promise<void> {
        log.info('abmeldenFahrzeug');

        await AppStorage.current.remove(Constants.ANGEMELDETES_FAHRZEUG);
        await AppStorage.current.remove(Constants.ANGEMELDETER_ANHAENGER);
        await AppStorage.current.remove(Constants.ANGEMELDETES_FAHRZEUG_DATE);
        await AppStorage.current.remove(Constants.ANGEMELDETER_ANHAENGER_DATE);

        App.current.fahrzeug.next(null);
        App.current.anhaenger.next(null);
    }

    public async getLetztesAngemeldetesFahrzeug(): Promise<string> {
        return await AppStorage.current.get(Constants.LETZTES_ANGEMELDETES_FAHRZEUG, true, true);
    }

    public async getLetztenAngemeldetenAnhaenger(): Promise<string> {
        return await AppStorage.current.get(Constants.LETZTER_ANGEMELDETER_ANHAENGER, true, true);
    }

    async entladeGeladeneTour() {
        const tour = App.current.aktuellGeladeneTour.getValue();

        if (!tour) {
            return;
        }

        App.loading(true);

        const result = await this.remoteService.entladeTour({
            TourKey: tour.TourKey
        });

        App.loading(false);

        if (result?.ErrorMessage) {
            UiHelper.showError(result.ErrorMessage);
            return;
        }

        App.current.aktuellGeladeneTour.next(null);

        await AppStorage.current.remove('aktuellGeladeneTour');

        await this.auftragService.syncAuftraegeMitServer('tour entladen');
    }

    async starteTour(tourStatus: TourStatus): Promise<boolean> {
        log.debug('starteTour', tourStatus);

        if (!tourStatus.Fahrzeug) {
            tourStatus.Fahrzeug = 'n/a';
        }

        if (!tourStatus.Abfahrtskontrolle) {
            tourStatus.Abfahrtskontrolle = [];
        }

        if (!tourStatus.FahrzeugKey) {
            tourStatus.FahrzeugKey = '';
        }

        if (!tourStatus.TourKey) {
            log.warn('Tour ohne TourKey gestartet. Generiere UUID');
            tourStatus.TourKey = Utils.uuid();
        }

        tourStatus.Guid = Utils.uuid();
        tourStatus.Statuszeit = moment().toISOString();
        tourStatus.FahrzeugKey = Utils.trimToEmpty(tourStatus.FahrzeugKey);
        tourStatus.Fahrzeug = Utils.trimToEmpty(tourStatus.Fahrzeug).toUpperCase();
        tourStatus.AnhaengerKey = Utils.trimToNull(tourStatus.AnhaengerKey);
        tourStatus.Anhaenger = Utils.toUpperCase(Utils.trimToNull(tourStatus.Anhaenger));
        tourStatus.ErstwiegungErfolgt = false;
        tourStatus.ZweitwiegungErfolgt = false;
        tourStatus.BeifahrerListe = [];

        Zeiterfassung.korrigiereBeifahrerListe(tourStatus);

        if (tourStatus.TourKey && tourStatus.TourTyp !== TourTyp.AdHoc) {
            // Tour wird explizit am Server gestartet und somit die Aufträge dem Gerät zugeordnet
            if (!App.isInternetAvailable()) {
                const ok = UiHelper.confirmJaNein('Keine Internetverbindung verfügbar. Tour trotzdem starten?');

                if (!ok) {
                    return;
                }
            }

            const result = await this.remoteService.starteTour({ TourKey: tourStatus.TourKey, TourKeys: tourStatus.TourKeys });

            if (!result) {
                UiHelper.showFehlerKommunikation();
                return;
            }

            if (!result.success) {
                UiHelper.showError(result.message, 'Fehler', false, false);
                return false;
            }

            await this.auftragService.syncAuftraegeMitServer('tour start');
        }

        let fahrzeug = await this.stammdatenService.getFahrzeug(tourStatus.Fahrzeug);

        if (!fahrzeug) {
            fahrzeug = {
                id: '',
                kennzeichen: tourStatus.Fahrzeug
            };
        }

        const anhaenger = await this.stammdatenService.getFahrzeug(tourStatus.Anhaenger);

        await this.anmeldenFahrzeug(fahrzeug, anhaenger);

        await AppStorage.current.set('aktuelleTour', tourStatus, true, true);

        // Copy
        const sendTourStatus: TourStatus = JSON.parse(JSON.stringify(tourStatus));

        if (sendTourStatus.Abfahrtskontrolle) {
            for (const a of sendTourStatus.Abfahrtskontrolle) {
                if (a.Bilder) {
                    for (const bild of a.Bilder) {
                        if (bild.BildGuid) {
                            bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                        }
                    }
                }
            }
        }

        if (sendTourStatus.AbfahrtskontrolleAnhaenger) {
            for (const a of sendTourStatus.AbfahrtskontrolleAnhaenger) {
                if (a.Bilder) {
                    for (const bild of a.Bilder) {
                        if (bild.BildGuid) {
                            bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                        }
                    }
                }
            }
        }

        const textdatei: Textdatei = {
            typ: TextdateiTyp.TourStatus,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            tourStatus: sendTourStatus
        };

        const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiTour === 'ja';

        // Kein await hier aus Performance-Gründen
        this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

        App.current.tourGestartet.next(true);
        App.current.aktuelleTour.next(tourStatus);
        App.current.aktuellGeladeneTour.next(null);

        await AppStorage.current.remove('aktuellGeladeneTour');

        await this.auftragService.aktualisiereAnzahlAktuelleAuftraege();

        return true;
    }

    async unterbrecheTour(): Promise<void> {
        App.current.tourGestartet.next(false);
    }

    async beendeTour(tourStatus: TourStatus): Promise<void> {
        log.info('beendeTour: ' + tourStatus.TourKey, tourStatus);

        tourStatus.Statuszeit = moment().toISOString();

        await Zeiterfassung.beendeLaufendeZeiterfassungOhneRueckfrage();

        // Beende die Zeiten von allen Beifahreren
        if (tourStatus.BeifahrerListe?.length) {
            for (const zeit of tourStatus.BeifahrerListe) {
                if (!zeit.AngemeldetBis) {
                    zeit.AngemeldetBis = Utils.nowIsoDateString();
                }
            }
        }

        await AppStorage.current.set('aktuelleTour', null, true, true);

        const sendTourStatus = Utils.clone(tourStatus);

        if (tourStatus.WiegescheinBilder) {
            for (const bild of sendTourStatus.WiegescheinBilder) {
                if (bild.BildGuid) {
                    bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                }
            }
        }

        const textdatei: Textdatei = {
            typ: TextdateiTyp.TourStatus,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            tourStatus: sendTourStatus
        };

        const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiTour === 'ja';

        // Kein await hier aus Performance-Gründen
        this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

        await this.abmeldenFahrzeug();

        App.current.tourGestartet.next(false);
        App.current.aktuelleTour.next(null);
        App.current.aktuellGeladeneTour.next(null);

        await AppStorage.current.remove('aktuellGeladeneTour');

        await this.auftragService.aktualisiereAnzahlAktuelleAuftraege();
    }

    async speichereAktuelleTour(): Promise<void> {
        await TourHelper.speichereAktuelleTour();
    }

    async syncTourenMitServer(info: string, fehlerAnzeigen = false): Promise<boolean> {
        try {
            log.debug('syncTourenMitServer: ' + info);

            // let touren = await AppStorage.current.get('touren');

            // if (!touren) {
            //     touren = [];
            // }

            const serverTouren = await this.remoteService.getAktuelleTouren();

            if (serverTouren) {
                await AppStorage.current.set('touren', serverTouren);
            } else {
                if (fehlerAnzeigen) {
                    UiHelper.showFehlerKommunikation();
                }
            }

            return true;
        } catch (err) {
            log.error('syncTourenMitServer: ' + Utils.getErrorMessage(err), err);

            if (fehlerAnzeigen) {
                UiHelper.showFehlerKommunikation(err);
            }

            return false;
        }
    }

    async getTourListe(remoteErlaubt: boolean): Promise<Tour[]> {
        let touren: Tour[] = await AppStorage.current.get('touren');

        if (!touren) {
            touren = [];
        }

        if (!touren.length) {
            await this.syncTourenMitServer('getTourListe');
        }

        for (const tour of touren) {
            if (!tour.Bezeichnung) {
                tour.Bezeichnung = tour.Key + ' - ' + tour.Datum;
            }
        }

        touren.sort((a, b) => {
            if (a.Bezeichnung > b.Bezeichnung) {
                return 1;
            } else if (a.Bezeichnung < b.Bezeichnung) {
                return -1;
            } else {
                return 0;
            }
        });

        return touren;
    }

    async pruefeArbeitszeitwarnungAnzeigen() {
        try {
            if (this.pruefeArbeitszeitwarnungLaeuft) {
                return;
            }

            this.pruefeArbeitszeitwarnungLaeuft = true;

            if (!AppConfig.current.arbeitszeitKonfiguration?.Warnungen) {
                return;
            }

            const isTourGestartet = App.current.tourGestartet.getValue()

            if (!isTourGestartet) {
                return;
            }

            const aktuelleTour = App.current.aktuelleTour.getValue();

            if (!aktuelleTour) {
                return;
            }

            let startzeit: moment.Moment;

            if (aktuelleTour.Datum && aktuelleTour.Uhrzeit) {
                startzeit = moment(aktuelleTour.Datum + ' ' + aktuelleTour.Uhrzeit + ':00', 'DD.MM.YYYY HH:mm:ss', 'de');
            } else {
                startzeit = moment(aktuelleTour.Statuszeit);
            }

            const now = moment();
            const duration = moment.duration(now.diff(startzeit));

            const aktivSeitStunden = duration.asHours();

            for (const warnung of AppConfig.current.arbeitszeitKonfiguration?.Warnungen) {
                if (!warnung.Zeit || warnung.Zeit < 0) {
                    // Deaktiviert
                    continue;
                }

                if (!warnung.MeldungText) {
                    continue;
                }

                if (!warnung.Key) {
                    // Verwende den Text als Key. Sollte eigentlich nie eintreten
                    warnung.Key = warnung.MeldungText;
                }

                let anzeigen = false;

                if (aktivSeitStunden >= warnung.Zeit) {
                    // Warnung steht prinzipiell an. Prüfe wann sie das letzte mal angezeigt wurde und ob es wieder an der Zeit ist
                    const letzteAnzeige = this.letzteArbeitszeitWarnungen[warnung.Key];

                    if (!letzteAnzeige) {
                        // Warnung wurde noch gar nicht angezeigt. Auf jeden Fall anzeigen
                        anzeigen = true;
                    } else {
                        // Warnung wurde bereits mindestens einmal angezeigt. Prüfe ob ein Intervall konfiguriert ist und ob es wieder Zeit ist.
                        if (warnung.Intervall > 0) {
                            const millisekundenSeitLetzterAnzeige = Date.now() - letzteAnzeige;
                            const minutenSeitLetzterAnzeige = millisekundenSeitLetzterAnzeige / (60 * 1000);

                            if (Math.round(minutenSeitLetzterAnzeige) >= warnung.Intervall) {
                                // Es ist mal wieder Zeit
                                anzeigen = true;
                            }
                        } else {
                            // Kein Intervall konfiguriert. Also auch nicht mehr anzeigen
                        }
                    }
                }

                if (anzeigen) {
                    // Hinweis soll angezeigt werden
                    // Prüfe ob diese Warnung bereits angezeigt wird
                    if (!this.aktiveArbeitszeitWarnungen[warnung.Key]) {
                        this.letzteArbeitszeitWarnungen[warnung.Key] = Date.now();
                        this.aktiveArbeitszeitWarnungen[warnung.Key] = true;

                        const localNotifications = (cordova.plugins as any).notification?.local;

                        localNotifications.schedule({
                            title: 'Arbeitszeit',
                            text: warnung.MeldungText,
                            vibrate: true,
                            wakeup: true,
                            badge: 1,
                            priority: 1,
                            lockscreen: true,
                            foreground: false,
                            launch: true,
                            sound: 'res://platform_default'
                            // actions: 'yes-no'
                        });

                        this.backgroundMode.wakeUp();
                        this.backgroundMode.moveToForeground();

                        await this.systemService.beepFuerNeueNachricht();

                        await UiHelper.showAlert(warnung.MeldungText, null, true);

                        this.letzteArbeitszeitWarnungen[warnung.Key] = Date.now();
                        this.aktiveArbeitszeitWarnungen[warnung.Key] = false;

                        const fahrer = App.current.fahrer.getValue();
                        const fahrzeug = App.current.fahrzeug.getValue();

                        const textdatei: Textdatei = {
                            typ: TextdateiTyp.TextInformation,
                            datum: moment().toISOString(),
                            geraeteNummer: AppConfig.current.geraeteNummer,
                            TextInformation: {
                                Text: 'Hinweismeldung bestätigt: ' + warnung.MeldungText,
                                Fahrzeug: fahrzeug?.kennzeichen,
                                FahrzeugKey: fahrzeug?.key,
                                Statuszeit: moment().toISOString(),
                                Personalnummer: fahrer?.personalnummer,
                                PersonalKey: fahrer?.Key,
                                Geraet: AppConfig.current.geraeteNummer
                            },
                        };

                        await this.systemService.sendeTextdatei(textdatei, false);
                    }
                }
            }
        } catch (err) {
            log.error("pruefeArbeitszeitwarnungAnzeigen: " + Utils.getErrorMessage(err), err);
        } finally {
            this.pruefeArbeitszeitwarnungLaeuft = false;
        }

        // const startDatum = moment(startzeit).startOf('day');

        // switch (AppConfig.current.einstellungen.TourAktivAnzeige) {
        //     case 'timer':
        //         const aktivDauer = ("0" + Math.floor(duration.asHours())).slice(-2) + ':' + ("0" + duration.minutes()).slice(-2) + ':' + ("0" + duration.seconds()).slice(-2).toString();

        //         this.tourAktivSeit1 = 'Tour aktiv seit ';
        //         this.tourAktivSeit2 = aktivDauer;

        //         aktuelleTour.Abfahrtskontrolle

        // }
    }
}